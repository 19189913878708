@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
    font-family: 'Roboto', sans-serif  !important;
}

:root {
    --primary-white: #fff ;
}

/***** Navbar ***********/
.logo {
    width: 16rem;
    height: 3rem;
}


.navbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 4px;
    background-color: #000000;
}

.navbar__link {
    margin: .3em;
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: .1em;
    text-indent: .1em;
    color: #ffffff;
    border-bottom: 3px solid transparent;
}

.navbar__link:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.navbar__link--active {
    border-bottom: 3px solid #df1ed6;
    transition: border-bottom .5s ease-in-out;
}

.card {
    padding: 1% 7%;
    color: #828291;
    background-color: #000000 !important;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

ul>li {
    padding: 4px
}

ul>li:hover {
    color: #957bda;
    cursor: pointer
}

hr {
    border-width: 3px
}

.social>i {
    padding: 1%;
    font-size: 15px
}

.social>i:hover {
    color: #957bda;
    cursor: pointer
}

.policy>div {
    padding: 4px
}

.divider {
    border-top: 2px solid rgba(189, 196, 203, 0.5);
}

footer{
    width:100%;
    height: 22%;
    bottom:0;
    left:0;
    background-color: #000000;
}

.slide1{
    height: 600px;  /* your desired height for this slide */
    background: url(/static/media/slide11.bcb2756c.JPG) no-repeat center center;
    background-size: cover;
}

.slide2{
    height: 600px;  /* your desired height for this slide */
    background: url(/static/media/slide21.830e0bf9.JPG) no-repeat center center;
    background-size: cover;
}
.slide3{
    height: 600px;  /* your desired height for this slide */
    background: url(/static/media/oprema.734e7c70.jpg) no-repeat center center;
    background-size: cover;
}
.slide4{
    height: 600px;  /* your desired height for this slide */
    background: url(/static/media/stupnik_sve.585d61d7.png) no-repeat center center;
    background-size: cover;
}
.top1 {
    width: 100%;
    height:390px;
    margin-top:5px;
    overflow: hidden;
}
